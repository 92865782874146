import './aboutus.css'

const AboutUs = () => {
    return ( 
        <section className='aboutus-container container-fluid'>

            <div className='aboutus'>
                <h2>About Us</h2>
                <h3>Welcome to The Grace Apartments</h3>
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Aut vero velit similique ut modi. Ullam fugit officia aliquam saepe similique.</p>
            </div>

            <div className='about-group container'>
                <div className='about-item'>
                    <h2> Who Are We </h2>
                    <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Possimus, voluptate vero nemo quasi repellendus distinctio, neque tenetur quod perferendis laboriosam velit pariatur. Delectus in optio eaque minus, dolor possimus fugit animi laborum distinctio, quia, amet sequi est. Ea deleniti maxime alias odit optio ad facilis nesciunt, ipsa enim quo perspiciatis?</p>
                </div>
                <div className='about-item'>
                    <h2> Our Mission </h2>
                    <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Possimus, voluptate vero nemo quasi repellendus distinctio, neque tenetur quod perferendis laboriosam velit pariatur. Delectus in optio eaque minus, dolor possimus fugit animi laborum distinctio, quia, amet sequi est. Ea deleniti maxime alias odit optio ad facilis nesciunt, ipsa enim quo perspiciatis?</p>
                </div>
                <div className='about-item'> 
                    <h2> What We do </h2>
                    <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Possimus, voluptate vero nemo quasi repellendus distinctio, neque tenetur quod perferendis laboriosam velit pariatur. Delectus in optio eaque minus, dolor possimus fugit animi laborum distinctio, quia, amet sequi est. Ea deleniti maxime alias odit optio ad facilis nesciunt, ipsa enim quo perspiciatis?</p>
                </div>
            </div>
            
        </section>
     );
}
 
export default AboutUs;