import './footer.css';
import { Link } from 'react-router-dom';
import footerLogo from '../../assets/thegracelogo-wht.png';

const Footer = () => {

    const currentYear = new Date().getFullYear()

    return ( 
        <footer className='container-fluid'>

            <div className='container footer-group'>
                <div className='footer-item'>
                <Link to = '/' className='main-logo'> <img src={footerLogo} alt='' className='img-fluid' /> </Link>
                </div>
                <div className='footer-link-group'>

                    <div className="footer-link-item">
                    <h4>Explore</h4>
                    <Link to = '/apartments' className='footer-link'>Apartments</Link>
                    <Link to = '/about' className='footer-link'>About Us</Link>
                    </div>

                    <div className="footer-link-item">
                    <h4>Contact</h4>
                    <p>+123-456-7890</p>
                    <p>hello@thegraceapartments</p>
                    </div>

                </div>
            </div>
            <hr></hr>
            <span className='container'>Copyright { currentYear } The Grace Apartments | All right Reserved</span>

        </footer>
    );
}
 
export default Footer;