import './amenities.css';
import security from '../../assets/security-icon.png';
import internet  from '../../assets/internet-icon.png';
import customer from '../../assets/customer-icon.png';
import power from '../../assets/power-icon.png';
import cleaner from '../../assets/cleaner-icon.png';
import bath from '../../assets/bath-icon.png';
import amenitiesImage from  '../../assets/amenities-image.png';

const Amenities = () => {
    return ( 
        <section className='amenities container'>

            <div className='amenities-1'>
            <h2>Amenities</h2>
            <p>The perfect blend of eco-friendly amenities to give you a comfortable stay.</p>
            <div className='amenities-group'>
                <div className='amenities-item'>
                    <img src={internet} alt='' className='img-fluid' />
                    <p>High speed Internet</p>
                </div>
                <div className='amenities-item'>
                    <img src = { power } alt='' className='img-fluid' />
                    <p>24/7 Power supply</p>
                </div>
                <div className='amenities-item'>
                    <img src={ bath } alt='' className='img-fluid' />
                    <p>Bathroom toileteries</p>
                </div>
                <div className='amenities-item'>
                    <img src={ cleaner } alt='' className='img-fluid' />
                    <p>Professionally cleaned</p>
                </div>
                <div className='amenities-item'>
                    <img src={ security } alt='' className='img-fluid' />
                    <p>24/7 Security</p>
                </div>
                <div className='amenities-item'>
                    <img src={ customer } alt='' className='img-fluid' />
                    <p>Speedy customer services</p>
                </div>
            </div>
            </div>

            <div className='amenities-2'>
                <img src={ amenitiesImage } alt='' className='img-fluid' />
            </div> 
            
        </section>
     );
}
 
export default Amenities;