import React, { useState, useRef } from 'react';
import CheckInDatePicker from '../CheckInDatePicker';
import CheckOutDatePicker from '../CheckOutDatePicker';
import './form.css';

const Form = ({apartment}) => {
    const formRef = useRef(null);
    const scriptURL = 'https://script.google.com/macros/s/AKfycbzIeCpDcSFKXzawKNUXr0jAxOyLCBJm7YobrBb5vs3-S2_0vZT7dI7FxT6-B9iBlQmA/exec';
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState('');
    const [formClass, setFormClass] = useState('');
    const [checkInDate, setCheckInDate] = useState(null);
    const [checkOutDate, setCheckOutDate] = useState(null);

    const handleCheckInDateChange = (date) => {
        setCheckInDate(date);
    };

    const handleCheckOutDateChange = (date) => {
        setCheckOutDate(date);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);

        try {
            const response = await fetch(scriptURL, {
                method: 'POST',
                body: new FormData(formRef.current),
            });

            if (response.ok) {
                setMessage('Booking Successful!');
                setFormClass('success-message');
                
                // setTimeout(() => {
                //     setFormClass('');
                //     setMessage('');
                // }, 5000);

                formRef.current.reset();
            } else {
                setMessage('Booking Failed. Please try again.');
                setFormClass('error-message');
            }

        } catch (error) {
            console.error('Error submitting form:', error);
            setMessage('An unexpected error occurred. Please try again later.');
            setFormClass('error-message');
        } finally {
            setLoading(false);
        }
    };

    return (
        <form onSubmit={handleSubmit} name="submit-to-google-sheet" ref={formRef}>
            <h2>Book this apartment</h2>

            <input type='text'
            name = 'apartment'
            hidden
            value={ apartment.title }
            className='form-control' />


            <label htmlFor="firstname"> First Name:
            <input type='text'
            name = 'firstName'
            className='form-control' />
        </label>

        <label htmlFor="lastname"> Last Name:
            <input type='text'
            name = 'lastName'
            className='form-control' />
        </label>

        <label htmlFor="phone"> Phone:
            <input type='tel'
            name = 'phone'
            className='form-control' />
        </label>

        <label htmlFor="email"> Email:
            <input type='email'
            name = 'email'
            className='form-control' />
        </label>

        <label htmlFor="guestNumber"> Guest Number:
            <input type='number'
            name =  'guestNumber' 
            className='form-control' />
        </label>

            <div className="checkDate">
                <div>
                    <label htmlFor="checkInDate">Check-In Date:</label>
                    <CheckInDatePicker selectedDate={checkInDate} onDateChange={handleCheckInDateChange} />
                </div>

                <div>
                    <label htmlFor="checkOutDate">Check-Out Date:</label>
                    <CheckOutDatePicker selectedDate={checkOutDate} onDateChange={handleCheckOutDateChange} />
                </div>
            </div>

            <input
                className="form-control btn btn-warning"
                type="submit"
                value={loading ? 'Please Wait...' : 'Reserve Apartment'}
            />
            <div className={formClass}>{message}</div>
        </form>
    );
};

export default Form;
