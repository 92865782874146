import Navbar from './components/navbar/Navbar';
import Home from './components/home/Home';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Footer from './components/footer/Footer';
import AboutUs from './components/aboutus/AboutUs';
import ApartmentList from './components/apartment/ApartmentList';
import ApartmentDetails from './components/apartment/ApartmentDetails';
import NoPage from './components/nopage/NoPage';



function App() {
  return (
    <BrowserRouter>
       <Navbar />
    <Routes>
      <Route index='/' element = {<Home />} />
      <Route path= '/apartments' element = { <ApartmentList /> } />
      <Route path= '/apartments/:id/:slug' element={ <ApartmentDetails  />} />
      <Route path = '/about' element = {<AboutUs />} />
      <Route path = '*' element = {<NoPage />} />
    </Routes>
    <Footer />
    </BrowserRouter>
  );
}

export default App;
