import Carousel from 'react-bootstrap/Carousel';
import './apartment.css';
import apartmentData from '../../data/index.js';
import { generateSlug } from '../../utils.js';
import { useParams } from 'react-router-dom';
import Form from '../form/Form.js';



const ApartmentDetails = () => {
    const { id, slug } = useParams();
    const apartment = apartmentData.find((apartment) => apartment.id === id);

    if (!apartment || generateSlug(apartment.title) !==slug) {
        return <div>Apartment not found</div>
    }

   
    return ( 
        <section className='apartment-detail-group container'>

            <main className='apartment-detail-info'>

            <Carousel className='apartment-detail-carousel-group'>

                { apartment.imageUrl.map((image, index) => (
                <Carousel.Item key={index} className='apartment-detail-carousel-item'>
                    <img src={`/images/${image}`} alt={`apartment ${index}`} className='img-fluid' />
                </Carousel.Item>
                ))}

            </Carousel>

                <div className='apartment-basic-info-group'>

                <div className='apaertment-basic-info-item'>
                    <div className='apartment-detail-title'>{apartment.title}</div>
                    <div className='apartment-detail-location'> {apartment.location} </div>
                </div>
        
                <div className='apartment-detail-price'> N{apartment.price} / Day </div>
                </div>

                <div className='apartment-detail'>
                    <span>{apartment.bed} Beds</span> <span>{apartment.bath} Bathrooms</span>
                </div>

                <div className='apartment-detail-description-group'>
                    <div className='apartment-detail-description-title'>Description</div>
                    <div className='apartment-description-item'>{apartment.description}</div>
                </div>

                <div className='apartment-service-title'>Apartment Services</div>

                <div className='apartment-service-group'>
                    <div className='apartment-service-item'>Airport Pickup and Drop Off (Complementary)</div>
                </div>

                <div className='apartment-service-group'>
                    <div className='apartment-service-title'>Car Hire Services</div>
                    <div className='apartment-service-item'>half day (30000)</div>
                    <div className='apartment-service-item'>full day (40000)</div>
                </div>

                <div className='apartment-service-group'>
                    <div className='apartment-service-item'>Food Ordering (Complimentary)</div>
                </div>

                <div className='apartment-service-group'>
                    <div className='apartment-service-title'>PlayStation 5</div>
                    <div className='apartment-service-item'>Quantity x1 (20000)</div>
                </div>
                
                <div className='apartment-service-group'>
                    <div className='apartment-service-item'> Personal Shopper Drinks & Grocery (Complimentary) </div>
                </div>

                <div className='apartment-service-group'>
                   <div  className='apartment-service-item'>In-House Chef</div>
                </div>

                <div className='apartment-service-group'>
                    <div className='apartment-service-title'> Game Request </div>
                    <div className='apartment-service-item'> Ludo (1000) </div>
                    <div className='apartment-service-item'> Chess (2000) </div>
                    <div className='apartment-service-item'> Jenga (10000) </div>
                    <div className='apartment-service-item'> Scramble (2000) </div>
                </div>

                <div className='apartment-service-group'>
                    <div className='apartment-service-title'>Headphones & Speakers</div>
                   <div className='apartment-service-item'>Quantity x1 (20000)</div>
                </div>

                <div className='apartment-service-group'>
                    <div className='apartment-service-title'>Media & Photography</div>
                    <div className='apartment-service-item'>Pictures only (50000)</div>
                    <div className='apartment-service-item'>Videos only (50000)</div>
                </div>

                <div className='booking-policies'>

                    <div className='book-policy-title'>Bookies Policies</div>

                    <div className='booking-policies-group'>
                        
                    <div className='house-rules'>
                        <div className='house-rules-title'>House rules</div>
                        <div className='apartment-service-item'>Check in: 2:00PM</div>
                        <div className='apartment-service-item'>Check out: 12:00PM</div>
                        <div className='apartment-service-item'>No Loitering</div>
                        <div className='apartment-service-item'>Please report damages immediately as soon as they occur</div>
                        <div className='apartment-service-item'>No parties, events or loud music allowed</div>
                    </div>

                    <div className='cancellation-group'>
                        <div className='cancellation-title'>Cancellation</div>
                        <div className='cancellation-item'>
                        Cancellation is free of charge 7 days prior to the scheduled arrival date or guests will be charged 100% of a 3-nights stay. If cancellation is made before 7 days to the scheduled arrival date, a full refund will be made to guests between 2-7 working days
                        </div>
                    </div>

                    </div>
                </div>
            </main>

            <aside className='apartment-detail-sidebar'>

                <div className='booking-form'>
                    <Form apartment={apartment} />
                </div>

            </aside>
        </section>
    );
}
 
export default ApartmentDetails;