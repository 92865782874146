import Carousel from 'react-bootstrap/Carousel';
import { Link } from "react-router-dom";
import { generateSlug } from '../../utils';
import './apartment.css';


const ApartmentCard = (props) => {
    return ( 
        <div className="apartment-item">
            <Carousel className='carousel-group'>

                { props.imageUrl.map((image, index) => (
                <Carousel.Item key={index} className='carousel-item'>
                    <img src={`/images/${image}`} alt={`apartment ${index}`} className='img-fluid' />
                </Carousel.Item>
                ))}

            </Carousel>

            <div className="apartment-info">
                <h2 className="apartment-title">{props.title}</h2>
                <div className="apartment-location">{props.location}</div>
                <div className="apartment-price">&#8358;{props.price}/Day</div>
                <div className="bedbath"> <span>{props.bed} Beds</span> <span>{props.bath} Bathrooms</span></div>
                <div className="apartment-description"> Elegant, Cultural, Inspiring. Experience Lagos and its rich history in the artsy Bamboo apartment.</div>
                <Link to = {`/apartments/${props.id}/${generateSlug(props.title)}`} className='apartment-link btn btn-warning'>View Details</Link>

                <div className="availability">Available</div>
            </div>
        </div>
     );
}
 
export default ApartmentCard;