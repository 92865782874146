import ApartmentCard from "./ApartmentCard";
import apartmentData from "../../data";
import './apartment.css';

const ApartmentList = () => {
    const apartment = apartmentData.map((apartment =>
         
        <ApartmentCard
        key = {apartment.id}
        id = {apartment.id}
        imageUrl = {apartment.imageUrl}
        title = {apartment.title}
        location = {apartment.location}
        price = {apartment.price}
        bed = {apartment.bed}
        bath = {apartment.bath}
        toilet = {apartment.toilet}
        /> 
        ))


    const handlePrice = () => {
        console.log('Order by price')
    }

    return ( 
        <div className="apartment-list container">
            <div className="button-group">
                <button className="btn btn-info" onClick={ handlePrice }>Sort by Price </button>
                <button className="btn btn-info">Sort A -Z </button>
                <button className="btn btn-info">Sort Location </button>
            </div>
            <div className="apartment-group">
                { apartment }
            </div>
        </div>
     );
}
 
export default ApartmentList;