// CheckInDatePicker.js
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const CheckOutDatePicker = ({ selectedDate, onDateChange }) => {
    
    const isFutureDate = (date) => {
        const currentDate = new Date();
    return date >= currentDate;
    };

  return (
    <DatePicker
      selected={ selectedDate }
      onChange={ onDateChange }
      name='checkout'
      placeholderText='Check out'
      minDate={ new Date() } // Set minimum date to today
      filterDate={ isFutureDate } // Only allow future dates
    />
  );
};

export default CheckOutDatePicker;
