import './header.css';


const Header = () => {
    return ( 
        <header> 
            <div className='header-heading'>
                <h1>The Grace Apartments</h1>
                <p>Your home away from home where comfort meets convinience in our charming short-let apartment</p>
            </div>
        </header>
    );
}
 
export default Header;