import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const CheckInDatePicker = ({ selectedDate, onDateChange }) => {
  const isFutureDate = (date) => {
    const currentDate = new Date();
    return date >= currentDate;
  };

  return (
    <DatePicker
      selected={ selectedDate }
      onChange={ onDateChange }
      name = 'checkin'
      placeholderText='Check in'
      minDate={ new Date() } // Set minimum date to today
      filterDate={ isFutureDate } // Only allow future dates
    />
  );
};

export default CheckInDatePicker;
