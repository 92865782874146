import './testimony.css';
import Carousel from 'react-bootstrap/Carousel';

const Testimonial = () => {
    return ( 
        <div className="testimonial">
            <h2>Testimonials</h2>
            <p>Read all about the stays and experiences our previous home renters have had. We show you all of it, not just the pretty stuff.</p>

            <div className="testimony-group container" >
                <div className="testimony-item">
                    <h3>Alex M. Sydney</h3>
                    <p>I had an amazing stay at the short let apartment. The attention to detail in the design and furnishings created a cozy atmosphere. The location was ideal for both work and leisure, with restaurants and attractions just a short walk away. I'll be recommending this place to all my friends! </p>
                </div>
                <div className="testimony-item">
                    <h3>Emmanuael Fresca</h3>
                    <p>My recent stay at the grace apartment was an absolute delight! The stylish decor and attention to detail made me feel right at home. The location was perfect for exploring the city, and the seamless check-in process added to the overall convenience. Highly recommend! </p>
                </div>
                <div className="testimony-item">
                    <h3>Mark Taiwo</h3>
                    <p>Choosing the The grace apartment for my business trip was the best decision. The fully equipped kitchen, comfortable bed, and reliable Wi-Fi made my stay productive and enjoyable. </p>
                </div>
            </div>
        </div>
     );
}
 
export default Testimonial;
