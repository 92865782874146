const apartmentData = [
    {
        id : "1",
        title : "3 bedroom apartment",
        type : "2 bedroom duplex",
        price : 150000,
        location : "Lekki Phase1",
        bed : 3,
        bath : 3,
        toilet : 1,
        isAvailable : true,
        imageUrl : [ "rex-apartment1.jpg",  "rex-apartment2.jpg", 'rex-apartment3.jpg', 'rex-apartment4.jpg' ],
        description : "Elegant, Cultural, Inspiring. Experience Lagos and its rich history in the artsy Bamboo apartment.",
    },

]
export default apartmentData