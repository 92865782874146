import './navbar.css';
import mainLogo from '../../assets/thegracelogo-blk.png';
import { Link } from 'react-router-dom';

const Navbar = () => {
    return (
        <nav className='container-fluid'>
            <div className='container navbar'>
                <Link to = '/' className='main-logo'> <img src={mainLogo} alt='' className='img-fluid' /> </Link>
                <div className='link-group'>
                    <Link to = 'apartments' className='link-item'>Apartments</Link>
                    <Link to = 'about' className='link-item'>About Us</Link>
                </div>
            </div>
        </nav>
      );
}
 
export default Navbar;