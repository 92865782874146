import Amenities from '../amenities/Amenities';
import Header from '../header/Header';
import Testimonial from '../testimony/Testimony';
import './home.css';

const Home = () => {
    return ( 
        <div className='home-container'>
            <Header />
            <Amenities />
            <Testimonial />
        </div>
     );
}
 
export default Home;